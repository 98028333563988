export default [
    {
        path: "operationDiyTag", //标签管理
        name: "operationDiyTag",
        meta: { title: false },
        component: () => import("@/views/home/operationCenter/diyTag.vue"),
    },
    {
        path: "approvalSetting", //审批设置
        name: "approvalSetting",
        meta: { title: false },
        component: () => import("@/views/home/operationCenter/approvalSetting.vue"),
    }
];
