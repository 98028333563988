import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";


//http封装请求
import axios from "axios";
axios.defaults.headers = "Content-Type:application/json;charset=UTF-8";
axios.defaults.withCredentials = true;
// 引入elementUI
Vue.use(ElementUI, { size: "small" });
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
//引入iconcss,js
import "@/assets/icons/font/iconfont.js";
import "@/assets/icons/font/iconfont.css";

//引入百度地图
import BaiduMap from "vue-baidu-map";
Vue.use(BaiduMap, { ak: "GunkrOwSDIPLdRUr0Q5nzGwOkgK4XmMM" });


//引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;

function reveolvePc() {
  var browser = require("browser-tool");
  let deviceManager = browser().device
  // 正式
  if (deviceManager == 'Mobile') {
    window.location.href = 'https://crm.haneton.com:7722/';
  } else {

  }
}
reveolvePc();



Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
