import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from '../views/HomeView.vue'
// 引入模块路由
import modles from "./homeChildren/modles";
import operation from './operationFile/index.js'
import hiddenPage from "./hiddenPage";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
  },
  {
    path: "/hreWhere",
    name: "hreWhere",
    component: () => import("@/views/hreWhere"),
  },
  {
    path: "/home",
    name: "home",
    redirect: "/home/client",
    component: () => import("@/views/home"),
    children: [...modles, ...operation, ...hiddenPage],
  },
  {
    path: "/crmQA", //QA问答
    name: "crmQA",
    component: () => import("@/views/home/operationCenter/QaPage.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
