<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { setWaterMark } from '@/utils/watermark/watermark'
import { getmaininfo } from '@/api/components'
export default {
  name: 'WorkspaceJsonApp',

  data() {
    return {

    };
  },

  mounted() {
    this.getmyinfo();
  },

  methods: {
    async getmyinfo() {
      let res = await getmaininfo();
      if (res.code == 200) {
        this.$store.state.nickName = res.data.nickName
        let newTime = new Date();
        let a = newTime.getFullYear();
        let b = newTime.getMonth() + 1;
        let c = newTime.getDate();
        let d = newTime.getHours();
        let e = newTime.getMinutes();
        setWaterMark(`${a}-${b}-${c} ${d}:${e}`, `${this.$store.state.nickName}`);
        this.$store.state.roleNmae = res.data.roles[0].roleName;
        this.$store.state.nickName = res.data.nickName
        this.myinfo = res.data;
        localStorage.setItem('roleName', res.data.roles[0].roleName)
        let str = this.myinfo.nickName.slice(0, 1);
        this.myinfo.strOne = str;
        this.$store.state.myinfoId = res.data.userId
        localStorage.setItem('myinfoId', res.data.userId)
      }
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}



a {
  font-size: 14px;
  text-decoration: none;
  color: rgb(96, 98, 102);
}

h3 {
  font-weight: bold;
}

li {
  list-style-type: none;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

/*滚动条样式*/
*::-webkit-scrollbar {
  z-index: 999999;
  width: 4px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.loadText {
  color: rgb(64, 158, 255);
  font-size: 12px;
  cursor: pointer;
}
</style>
