import axios from "axios";
import { Message } from "element-ui";
import Vue from "vue";
import router from "@/router";
const request = axios.create({
  baseURL: "/api",
  retry: 3,
  timeout: 20 * 1000,
  retryDelay: 1000,
});

//防抖处理-立即执行
const on = Vue.prototype.$on;
Vue.prototype.$on = function (event, func) {
  let timer;
  let flag = true;
  let newFunc = func;
  if (event === "click") {
    newFunc = function () {
      if (flag) {
        func.apply(this, arguments);
        flag = false;
      } else {
        Message.warning("请勿重复点击");
      }
      clearTimeout(timer);
      timer = setTimeout(function () {
        flag = true;
      }, 800);
    };
  }
  on.call(this, event, newFunc);
};

//添加请求拦截器
request.interceptors.request.use(function (config) {
  if (localStorage.getItem("Token")) {
    config.headers.Authorization = localStorage.getItem("Token");
  }
  return config;
}, (error) => {
  //超时处理 error.config是一个对象，包含上方create中设置的三个参数
  var config = error.config;
  if (!config || !config.retry) return Promise.reject(error);

  // __retryCount用来记录当前是第几次发送请求
  config.__retryCount = config.__retryCount || 0;

  // 如果当前发送的请求大于等于设置好的请求次数时，不再发送请求，返回最终的错误信息
  if (config.__retryCount >= config.retry) {
    return Promise.reject(error);
  }

  // 记录请求次数+1
  config.__retryCount += 1;

  // 设置请求间隔 在发送下一次请求之前停留一段时间，时间为上方设置好的请求间隔时间
  var backoff = new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, config.retryDelay || 1);
  });
  if (error.message.includes('timeout')) {
    Message({ type: error, message: "请求服务器超时，请重试", duration: 30 * 1000 })
  }
  // 再次发送请求
  return backoff.then(function () {
    return requests(config);
  });
});
//添加相应拦截器
request.interceptors.response.use(
  (res) => {
    if (res.data.code == 401) {
      //传入loginOut
      window.localStorage.setItem('loginOut', false)
      //跳转到登录页
      router.push('/login')
    } else if (res.data.code == 500) {

    }
    // 将token过期标记为true
    return res.data;
  },
  (error) => {
    // return Promise.reject(error)
    if (error.message == "Request failed with status code 500") {
      Message({
        message: `${"请求超时"}`,
        type: "error",
      });
    } else if (error.response.status !== 500) {
      Message({
        message: `${"网络错误请稍后重试"}`,
        type: "error",
      });
      setTimeout(() => {
        router.push("/");
      }, 1000);
    }
    return Promise.reject(error);
  }
);

export default request;
