export default [
  {
    path: "instrument", //仪表盘
    name: "instrument",
    meta: false,
    icon: "iconfont icon-gongyezujian-yibiaopan",
    component: () => import("@/views/home/children/instrumentPage/instrument"),
  },
  // {
  //   path: "backlog", //发票
  //   name: "backlog",
  //   meta: "待办事项",
  //   icon: "iconfont icon-fapiao",
  //   component: () => import("@/views/home/children/backlogPage/backlog"),
  // },
  {
    path: "thread", //线索
    name: "thread",
    meta: { title: "线索" },
    icon: "iconfont icon-xiansuo",
    component: () => import("@/views/home/children/threadPage/thread"),
  },
  // {
  //   path: "threadedTree", //线索池
  //   name: "threadedTree",
  //   meta: { title: "线索池" },
  //   icon: "iconfont icon-hailiangxiansuo",
  //   component: () =>
  //     import("@/views/home/children/threadedTreePage/threadedTree"),
  // },
  {
    path: "client", //客户
    name: "client",
    meta: { title: "客户" },
    icon: "iconfont icon-kehu",
    component: () => import("@/views/home/children/clientPage/client"),
  },
  {
    path: "terminalPage", //终端驳回
    name: "terminalPage",
    meta: { title: "终端客户", show: "吴静" },
    icon: "iconfont icon-zhongduan",
    component: () => import("@/views/home/children/terminal"),
  },
  // {
  //   path: "CustomerPool", //客户池
  //   name: "CustomerPool",
  //   meta: { title: "客户池" },
  //   icon: "iconfont icon-kehutong",
  //   component: () =>
  //     import("@/views/home/children/CustomerPoolPage/CustomerPool"),
  // },
  {
    path: "ActiveRecord", //活动记录
    name: "ActiveRecord",
    meta: { title: "活动记录" },
    icon: "iconfont icon-buxing",
    component: () =>
      import("@/views/home/children/activationRecordPage/activeRecord.vue"),
  },
  {
    path: "Contact", //联系人
    name: "Contact",
    meta: { title: "联系人" },
    icon: "iconfont icon-lianxiren",
    component: () => import("@/views/home/children/ContactPage/Contact"),
  },
  {
    path: "businessInquiry", //工商信息查询
    name: "businessInquiry",
    meta: { title: "工商信息查询" },
    icon: "iconfont icon-hailiangxiansuo",
    component: () => import("@/views/home/children/businessInquiry/businessInquiry"),
  },
  // {
  //   path: "areaUnder", //辖区范围
  //   name: "areaUnder",
  //   meta: "辖区范围",
  //   icon: "iconfont icon-dingwei",
  //   component: () => import("@/views/home/children/areaUnder/areaUnder"),
  // },
  // {
  //   path: "manual", //操作手册
  //   name: "manual",
  //   meta: "操作手册",
  //   icon: "iconfont icon-zhishiku",
  //   component: () => import("@/views/home/children/manualPage"),
  // },
  // {
  //   path: "Business", //商机
  //   name: "Business",
  //   meta: "商机",
  //   icon: "iconfont icon-shangjiguanli",
  //   component: () => import("@/views/home/children/BusinessPage/Business"),
  // },
  {
    path: "contract", //业务
    name: "contract",
    meta: { title: "业务" },
    icon: "iconfont icon-hetong",
    component: () => import("@/views/home/children/contractPage/contract"),
  },
  {
    path: "approval", //审批
    name: "approval",
    // meta: { title: "审批" },
    icon: "iconfont icon-tianshenpi",
    component: () => import("@/views/home/children/approvalPage/approval"),
  },
  {
    path: "terminal", //终端驳回
    name: "terminal",
    // meta: { title: "审批" },
    icon: "iconfont icon-tianshenpi",
    component: () => import("@/views/home/children/terminalReturn"),
  },
];
