import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isshowdrawer: false,
    isshowdrawerOne: false,
    expertSearch: false,
    uploadeExcel: false,
    batchId: "",
    onesId: "",
    myinfoId: "",
    tagcut: 1,
    advancedSearch: false,
    checkWin: false,
    contactsId: null,
    isshowAddNew: false,
    loading: false,
    tableData: [],
    showTabS: {},
    carstormType: "",
    edtiIsShowDrawer: false,
    showtagS: false,
    editList: [],
    activeUserDeptName: '',
    nickName: '',
    approvalTotal: 0,
    userId: null,
    AuthorizationStatus: null,
    referenceMobile: null,
    editType: false,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
