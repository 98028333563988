// 组件内请求
import request from "@/utils/request";
// 获取客户下拉列表
export const GetSelectClientList = (data) => {
  return request({
    url: "crm/crmCustomer/list",
    method: "post",
    data,
  });
};

// 获取字典表内职位信息
export const Getdisctionries = (data) => {
  return request({
    url: `system/dict/data/type/position_type`,
    method: "get",
    data,
  });
};

//获取字典列表
export const getdiction = () => {
  return request({
    url: `system/dict/data/list`,
    method: "get",
  });
};

//根据type值获取字典表中信息
export const getdictionlist = (data) => {
  return request({
    url: `system/dict/data/type/${data} `,
    method: "get",
  });
};
//获取活动详情列表
export const getactiveinfo = (data) => {
  return request({
    url: "crm/crmActivityRecord/queryActivityRecordList",
    method: "post",
    data,
  });
};

//详情弹窗添加活动记录
export const addnewFollow = (data) => {
  return request({
    url: "crm/crmActivityRecord/add",
    method: "post",
    data: data,
  });
};

//查询用户列表
export const getUserList = (data) => {
  return request({
    url: "system/user/list",
    method: "get",
    params: data,
  });
};

//查询 用户列表  不分页
export const getUserListNoPage = (data) => {
  return request({
    url: `system/user/getUserlistByDept?deptId=${data}`,
    method: "get",
  });
};

//查询用户详情
export const getmaininfo = (data) => {
  return request({
    url: "system/user/profile",
    method: "get",
    data,
  });
};

//获取池下拉（权限）
export const getpollselect = (data) => {
  return request({
    url: "/crm/crmCustomerPool/queryPoolListByLoginUser",
    method: "get",
    data,
  });
};

//获取池下拉(全部)
export const getpollAllselect = (data) => {
  return request({
    url: "/crm/crmCustomerPool/list",
    method: "get",
    data,
  });
};
//获取用户列表(全部)
export const getUserAllList = (data) => {
  return request({
    url: "system/user/allUserList",
    method: "get",
    data,
  });
};
//获取新增联系人弹窗-负责人下拉
export const Getprincipal = (data) => {
  return request({
    url: "system/user/list?status=0",
    method: "get",
    data,
  });
};

//获取省
export const Getprovince = (data) => {
  return request({
    url: "crm/province/list",
    method: "get",
    data,
  });
};
//获取市
export const Getcity = (data) => {
  return request({
    url: `crm/city/list?${data}`,
    method: "get",
  });
};
//获取区
export const Getarea = (data) => {
  return request({
    url: `crm/area/list?${data}`,
    method: "get",
  });
};
//查询团队协作列表
export const Getteam = (data) => {
  return request({
    url: `crm/crmCustomerCollaborator/queryTeaMembersByBatchId`,
    method: "post",
    data,
  });
};
//查询部门树 
export const getbranchTree = (data) => {
  return request({
    url: `system/dept/allList`,
    method: "get",
    data,
  });
};

//查询部门树（权限）
export const getbranchTreelimit = (data) => {
  return request({
    url: `system/dept/list`,
    method: "get",
    data,
  });
};

//团队——添加协作人
export const addnewUser = (data) => {
  return request({
    url: "crm/crmCustomerCollaborator/add",
    method: "post",
    data,
  });
};
//高级搜索
export const spackSearch = (data) => {
  return request({
    url: "crm/crmCustomer/search",
    method: "post",
    data,
  });
};
//团队——删除协作人
export const deletUser = (data) => {
  return request({
    url: "crm/crmCustomerCollaborator/remove",
    method: "delete",
    data,
  });
};

//团队——修改协作人
export const editUser = (data) => {
  return request({
    url: "crm/crmCustomerCollaborator/edit",
    method: "put",
    data,
  });
};

//上传Excel
export const upLoadExcelo = () => {
  return request({
    url: "crm/crmCustomer/importData",
    method: "post",
    headers: {
      'content-type': 'multipart/form-data'
    },
    responseType: "blob",
  });
};



//上传Excel查询工商信息
export const UploadExcelFindBunss = () => {
  return request({
    url: "crm/crmCustomer/importBussinessInformationData",
    method: "post",
    headers: {
      'content-type': 'multipart/form-data'
    },
    responseType: "blob",
    timeout: 600 * 1000,
  });
};




//下载Excel
export const downLoadExcelo = () => {
  return request({
    url: "crm/crmCustomer/downloadCustomerImportExcelTemplate",
    method: "get",
    responseType: "blob",
  });
};

//主页——查重工具
export const checkUser = (data) => {
  return request({
    url: "crm/crmCustomer/check",
    method: "post",
    data
  });
};

//辖区范围
export const areaUderList = (data) => {
  return request({
    url: "crm/jurisdictions/queryJurisdictions",
    method: "get",
    data
  });
};


//下载Excel模板
export const downLoadExcelTem = (data) => {
  return request({
    url: `crm/crmCustomer/downloadExcelTemplate?type=${data}`,
    method: "get",
    responseType: 'blob',
    timeout: 600000
  });
};


//查询招投标数信息
export const findbidInfo = (data) => {
  return request({
    url: '/crm/crmCustomer/queryEnterpriseBidNumbers',
    method: "post",
    data
  });
};




//上传图片
export const uploadImgPost = (data) => {
  return request({
    url: `crm/fileInfo/uploadImg`,
    method: "post",
    headers: {
      'content-type': 'multipart/form-data'
    },
    data,
  });
};



//导出Excel招投标数
export const deriveExcel = (data) => {
  return request({
    url: `crm/customerBussinessInformation/batchExportBiddingNumber`,
    method: "post",
    responseType: 'blob',
    data,
    // timeout: 600000
  });
};


//部门树
export const deptTree = (data) => {
  return request({
    url: `system/user/deptTree`,
    method: "get",
    data,
  });
};


//查询是否已存在该用户
export const findUserInUserName = (data) => {
  return request({
    url: `crm/crmCustomer/checkCustomer`,
    method: "post",
    data,
  });
};

//查询上传文件历史数据
export const findUploadHistoryData = (data) => {
  return request({
    url: `crm/impartRecord/list`,
    method: "post",
    data,
  });
};

//查询上传文件历史数据
export const batchFindbidNum = (data) => {
  return request({
    url: `crm/customerBussinessInformation/batchQueryBiddingNumber`,
    method: "post",
    data,
  });
};


//向企业发送注册短信
export const RegisterSMS = (data) => {
  return request({
    url: `/crm/worktop/sendRegisterSMS`,
    method: "post",
    data,
  });
};

//补充社会统一信用代码
export const supplementCode = (data) => {
  return request({
    url: `crm/crmCustomer/addCustomerEnterpriseCreditCode`,
    method: "post",
    data,
  });
};



//部门树状图接口
export const deptTreeList = (data) => {
  return request({
    url: `/system/dept/deptTree`,
    method: "post",
    data,
  });
};


//手动降级
export const commdownLevel = (data) => {
  return request({
    url: `/crm/crmCustomer/manualReduceTerminalLevel`,
    method: "post",
    data,
  });
};
